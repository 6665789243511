import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import {
    AppBase,
    ScrollToTop,
    TrackedRoute,
    TranslatePage,
    useEvents,
    UserNavigationEvent,
} from "@ingka-livlig/frontend-lib";
import { QueryParamProvider, StringParam, useQueryParam } from "use-query-params";
import { MapView } from "./MapView";
import { Map3DView } from "./Map3DView";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useUserAPI } from "./userAPI";
import { ChooseLocation, InitialRedirect } from "./ChooseLocation";
import { Training } from "./Training";
import Features from "./Features";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { useLingui } from "@lingui/react";
import * as englishMessages from "./locales/en/messages.mjs";
import { RemoteLogin, RemoteLoginValidate } from "./RemoteLogin";

/**
 * Just redirect the browser based on what's in the "goto" query param.
 * @constructor
 */
function GotoPage() {
    const [goto] = useQueryParam("goto", StringParam);
    if (goto) {
        window.location.replace(goto);
        return null;
    } else {
        return <Navigate to={"/"} replace />;
    }
}

const Translate = () => {
    const api = useUserAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

function AppRoutes() {
    const events = useEvents<UserNavigationEvent>(useUserAPI());
    /**
     * This is put here in order to force Lingui to remount when language changes.
     * With Lingui 4 the default is to only rerender when language us changed. This works nicely when <Trans> elements
     * are used, but doesn't work when the t`...` templated string is used. The result is a lot of untranslated strings.
     * We handle this by remounting everything, which shouldn't be an issue as it's expected that users seldomly
     * change languages.
     *
     * In addition, we rely on the selected locale to fetch proper names of HFBs and PAs from the server, so we need to
     * trigger a refetch anyway.
     */
    useLingui();

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <TrackedRoute routeName="Root" events={events}>
                        <InitialRedirect />
                    </TrackedRoute>
                }
            />
            <Route
                path="/choose-location"
                element={
                    <TrackedRoute routeName="ChooseLocation" events={events}>
                        <ChooseLocation />
                    </TrackedRoute>
                }
            />
            <Route
                path="/translate"
                element={
                    <TrackedRoute routeName="Translate" events={events}>
                        <Translate />
                    </TrackedRoute>
                }
            />
            <Route
                path="/map"
                element={
                    <TrackedRoute routeName="Map" events={events}>
                        <MapView />
                    </TrackedRoute>
                }
            />
            <Route
                path="/map3d"
                element={
                    <TrackedRoute routeName="Map3D" events={events}>
                        <Map3DView />
                    </TrackedRoute>
                }
            />
            <Route path="/goto-page" element={<GotoPage />} />
            <Route
                path="/training"
                element={
                    <TrackedRoute routeName="Training" events={events}>
                        <Training />
                    </TrackedRoute>
                }
            />
            <Route
                path="/training/lesson/:lessonId"
                element={
                    <TrackedRoute routeName="Lesson" events={events}>
                        <Training />
                    </TrackedRoute>
                }
            />
            <Route
                path="/features"
                element={
                    <TrackedRoute routeName="Features" events={events}>
                        <Features />
                    </TrackedRoute>
                }
            />
            <Route path="/qr" element={<RemoteLogin />}></Route>
            <Route
                path="/qr/:sessionId"
                element={
                    <TrackedRoute routeName="RemoteLogin" events={events}>
                        <RemoteLoginValidate />
                    </TrackedRoute>
                }
            />
            <Route
                path="*"
                element={
                    <TrackedRoute routeName="NotFound" events={events}>
                        <NotFound />
                    </TrackedRoute>
                }
            />{" "}
        </Routes>
    );
}

function MainRouter() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <AppBase localeActivate={localeActivate}>
                <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <AppRoutes />
                </QueryParamProvider>
            </AppBase>
        </BrowserRouter>
    );
}

export default withStore(MainRouter, {});
