import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./MapView.module.css";
import {
    globalLocation,
    LocationScopeWithMetadata,
    NavHome,
    ScopeType,
    transformLocation,
} from "@ingka-livlig/frontend-lib";
import { useUserAPI } from "./userAPI";
import { MapChart, useMapRates, ZoomLevel } from "@ingka-livlig/frontend-map";
import { t } from "@lingui/macro";
import { Helmet } from "react-helmet";
import { useSalesStreams } from "./salesAPI";

const ScopeSelector: React.FC<{
    location: LocationScopeWithMetadata;
    preSelectedScope: ScopeType;
    changeCallback: (newScope: ScopeType) => void;
}> = ({ location, preSelectedScope, changeCallback }) => {
    const [selectedScope, setSelectedScope] = useState<ScopeType>(preSelectedScope);

    const selectFun = (scope: ScopeType) => {
        if (scope !== selectedScope) {
            changeCallback(scope);
            setSelectedScope(scope);
        }
    };

    return (
        <div className={styles.scopeSelector}>
            <div
                className={`${styles.scope} ${selectedScope === "Global" && styles.scopeSelected}`}
                onClick={() => selectFun("Global")}
            >
                Global
            </div>
            {(location.scope === "Country" || location.scope === "SellingUnit") && (
                <div
                    className={`${styles.scope} ${selectedScope === "Country" && styles.scopeSelected}`}
                    onClick={() => selectFun("Country")}
                >
                    {location.countryName}
                </div>
            )}
            {location.scope === "SellingUnit" && (
                <div
                    className={`${styles.scope} ${selectedScope === "SellingUnit" && styles.scopeSelected}`}
                    onClick={() => selectFun("SellingUnit")}
                >
                    {location.sellingUnitName}
                </div>
            )}
        </div>
    );
};

export const MapView = () => {
    const userApi = useUserAPI();
    const [location, setLocation] = useState<LocationScopeWithMetadata>();
    const [selectedScope, setSelectedScope] = useState<ScopeType>();
    const [zoom, setZoom] = useState<ZoomLevel>();
    const salesLocation = location && selectedScope && transformLocation(location, selectedScope);
    const [events, minuteStats, secondStats] = useSalesStreams(salesLocation);
    const rates = useMapRates(selectedScope, minuteStats, secondStats, location?.currency.name || "Euro");

    useEffect(() => {
        userApi
            .userLocationWitMetadata()
            .then((loc) => {
                setLocation(loc || globalLocation);
            })
            .catch(() => {
                setLocation(globalLocation);
            });
    }, []);

    useEffect(() => {
        setSelectedScope(location?.scope);
    }, [JSON.stringify(location)]);

    useEffect(() => {
        if (location && selectedScope) {
            setZoom(calcZoomLevel(location, selectedScope));
        } else {
            setZoom(undefined);
        }
    }, [selectedScope]);

    return (
        <div className={styles.container} data-testid="main">
            <Helmet>
                <title>{t`Real-time sales` + " | Livlig"}</title>
            </Helmet>
            <div className={styles.header}>
                <nav className={styles.navigation}>
                    <NavHome homeUrl={`/`} text={t`Real-time sales`} />
                </nav>
                <Link className={styles.hiddenLink} to={"/map3d"}>
                    3D
                </Link>
            </div>
            {location && selectedScope && (
                <ScopeSelector location={location} preSelectedScope={selectedScope} changeCallback={setSelectedScope} />
            )}
            <div className={styles.mapContainer}>
                <MapChart className={styles.mapChart} eventStream={events} rates={rates} zoom={zoom} />
            </div>
        </div>
    );
};

function calcZoomLevel(location: LocationScopeWithMetadata, selectedScope: ScopeType): ZoomLevel | undefined {
    switch (selectedScope) {
        case "Country":
            if (location.scope === "Country" || location.scope === "SellingUnit") {
                return { scope: "Country", countryCode: location.countryCode };
            } else {
                return undefined;
            }
        case "SellingUnit":
            if (location.scope === "SellingUnit") {
                return {
                    scope: "SellingUnit",
                    countryCode: location.countryCode,
                    sellingUnitCode: location.sellingUnitCode,
                    sellingUnitLat: location.sellingUnitLocation.lat,
                    sellingUnitLng: location.sellingUnitLocation.lng,
                };
            } else {
                return undefined;
            }
        default:
            return undefined;
    }
}
