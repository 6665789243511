import React, { ChangeEvent, useEffect, useState } from "react";
import Select, { Option } from "@ingka/select";
import styles from "./SelectLocation.module.css";
import { t, Trans } from "@lingui/macro";
import Checkbox from "@ingka/checkbox";
import { locales, localesInEnglish, translateCountryName } from "@ingka-livlig/frontend-lib";
import { Country } from "./metadataAPI";
import { countryCode, LocationScope, sellingUnitCode } from "@ingka-livlig/frontend-lib";

export const LocationSelector: React.FC<{
    initialLocation: LocationScope | null;
    initialLocale: string | null;
    countries: Array<Country>;
    onLocationChange: (selection: LocationScope | null) => void;
    onLanguageChange: (selection: string | null) => void;
}> = ({ initialLocation, initialLocale, countries, onLocationChange, onLanguageChange }) => {
    const [showInternal, setShowInternal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<LocationScope | null>(initialLocation || null);
    const selectedCountryCode = countryCode(selectedLocation);
    const selectedSellingUnitCode = sellingUnitCode(selectedLocation);
    const [selectedLocale, setSelectedLocale] = useState<string | null>(initialLocale || null);

    const shouldShowEnglishNamesToo = true;
    const country = countries.find((entry) => selectedCountryCode === entry.id);

    const languages = Object.entries(locales)
        .sort((lhs, rhs) => lhs[1].localeCompare(rhs[1]))
        .map((locale) => {
            let label = locale[1];
            if (shouldShowEnglishNamesToo && locale[0] != "en") {
                // @ts-ignore
                let englishName = `${localesInEnglish[locale[0]]}`;
                label = `${locale[1]} (${englishName})`;
            }
            return {
                id: locale[0],
                label: label,
            };
        });

    useEffect(() => {
        if (selectedLocation) {
            switch (selectedLocation.scope) {
                case "Global":
                case "Country":
                case "SellingUnit":
                    onLocationChange(selectedLocation);
                    break;
            }
        }
    }, [selectedLocation]);

    useEffect(() => {
        if (selectedLocale) {
            onLanguageChange(selectedLocale);
        }
    }, [selectedLocale]);

    return (
        <div className={styles.selectLocation}>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="lang"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Language</Trans>
                        </span>
                    }
                    value={selectedLocale || "en"}
                    onChange={(event) => {
                        const locale = event.target.value as string;
                        setSelectedLocale(locale);
                    }}
                    hintText=""
                >
                    {languages.map((entry) => (
                        <Option key={entry.id} value={entry.id} name={entry.label} />
                    ))}
                </Select>
            </div>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="country"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Country</Trans>
                        </span>
                    }
                    value={selectedCountryCode || "all"}
                    onChange={(event) => {
                        const countryCode = event.target.value as string;
                        setSelectedLocation(
                            countryCode === "all" ? { scope: "Global" } : { scope: "Country", countryCode },
                        );
                    }}
                    hintText=""
                >
                    <Option key="all" value="all" name={t`All countries (Global)`} />
                    {countries.map((entry) => (
                        <Option key={entry.id} value={entry.id} name={translateCountryName(entry.name)} />
                    ))}
                </Select>
            </div>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="su"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Selling unit</Trans>
                        </span>
                    }
                    value={selectedSellingUnitCode || "all"}
                    onChange={(event) => {
                        if (selectedCountryCode && selectedCountryCode !== "all") {
                            const sellingUnitCode = event.target.value as string;
                            const scope: LocationScope =
                                sellingUnitCode === "all"
                                    ? { scope: "Country", countryCode: selectedCountryCode }
                                    : { scope: "SellingUnit", countryCode: selectedCountryCode, sellingUnitCode };
                            setSelectedLocation(scope);
                        }
                    }}
                    hintText=""
                >
                    <Option key="all" value="all" name={t`All business units`} />
                    {country?.sellingUnits
                        .filter((entry) => showInternal || !entry.isInternal)
                        .map((entry) => (
                            <Option
                                key={entry.id}
                                value={entry.id}
                                name={entry.id.length === 8 ? entry.name : `${entry.name}*`}
                            />
                        ))}
                </Select>
            </div>
            <div className={styles.checkBox}>
                <Checkbox
                    id={"internal"}
                    value={""}
                    checked={showInternal}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setShowInternal(event.target.checked)}
                    label={
                        <span className={styles.internalCheckbox}>
                            <Trans>Also show internal locations</Trans>
                        </span>
                    }
                    title={t`If enabled, "Pick-up Points" will also be shown in the list.`}
                />
            </div>
        </div>
    );
};
