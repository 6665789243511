import { Menu, NotFound as NF, Page, PageHeader } from "@ingka-livlig/frontend-lib";
import { t } from "@lingui/macro";
import { AppSettings } from "./AppSettings";

export default function NotFound() {
    return (
        <Page
            title={t`Home`}
            headerElement={
                <PageHeader headerText="Livlig" actionElement={<AppSettings />}>
                    <Menu currentApp="landing" />
                </PageHeader>
            }
        >
            <NF />
        </Page>
    );
}
