import React, { ChangeEvent, useEffect, useState } from "react";
import Select, { Option } from "@ingka/select";
import styles from "./ChooseLocation.module.css";
import {
    Center,
    countryCode,
    extractSelectedLanguage,
    Footer,
    globalLocation,
    LivligButton,
    LoadingIndicator,
    locales,
    localesInEnglish,
    LocationScope,
    LocationScopeWithMetadata,
    persistLanguageSetting,
    sellingUnitCode,
    Splash,
    translateCountryName,
    unlessSuccessful,
    useApiCall,
} from "@ingka-livlig/frontend-lib";
import { t, Trans } from "@lingui/macro";
import { Link, Navigate } from "react-router-dom";
import { Country, useMetadataAPI } from "./metadataAPI";
import { useUserAPI } from "./userAPI";
import { MapChart, useMapRates, ZoomLevel } from "@ingka-livlig/frontend-map";
import { useLocationsAPI } from "./locationsAPI";
import { extractDomainTLD } from "./domainUtil";
import Logo from "./Logo";
import { StringParam, useQueryParam } from "use-query-params";
import Checkbox from "@ingka/checkbox";
import { localeActivate } from "./i18n";
import { I18n, setupI18n } from "@lingui/core";
import { I18nProvider } from "@lingui/react";
import { useSalesStreams } from "./salesAPI";

export function InitialRedirect() {
    const userApi = useUserAPI();
    const userLocationReq = useApiCall(userApi.userLocationDetails);

    switch (userLocationReq.tag) {
        case "loading":
            return (
                <div className={styles.page}>
                    <Splash />
                </div>
            );
        case "success":
            if (userLocationReq.value.stored) {
                window.location.href = constructSalesUrl(userLocationReq.value.stored);
                return (
                    <div className={styles.page}>
                        <Splash />
                    </div>
                );
            } else {
                return <Navigate to="/choose-location" replace />;
            }
        // For error cases we default to showing the location chooser to avoid getting the user stuck.
        case "not-found":
        case "error":
            return <Navigate to="/choose-location" replace />;
    }
}

export function ChooseLocation() {
    const locationsApi = useLocationsAPI();
    const userApi = useUserAPI();
    const countries = unlessSuccessful(useApiCall(useMetadataAPI().countries), null);
    const userLocationReq = useApiCall(userApi.userLocationDetails);
    const initialLocation =
        userLocationReq.tag === "success" ? userLocationReq.value.stored || userLocationReq.value.guessed : null;
    const [location, setLocation] = useState<LocationScopeWithMetadata | null>(initialLocation);
    const [returnUrl] = useQueryParam("ret", StringParam);

    const [events, minuteStats, secondStats] = useSalesStreams(location || globalLocation);
    const rates = useMapRates(location?.scope, minuteStats, secondStats, location?.currency.name || "Euro");

    const browserLang = navigator.language.slice(0, 2);
    const lang = extractSelectedLanguage();

    // @ts-ignore
    const browseLocale = locales[browserLang] as string | undefined;

    const languageAlert =
        browserLang != "en" && browserLang != lang && browseLocale ? (
            <LocaleAlert locale={browserLang} localNameOfLocale={browseLocale} />
        ) : null;

    let mapZoom: ZoomLevel | undefined = undefined;
    switch (location?.scope) {
        case "Country":
            mapZoom = { scope: "Country", countryCode: location.countryCode };
            break;
        case "SellingUnit":
            mapZoom = {
                scope: "SellingUnit",
                countryCode: location.countryCode,
                sellingUnitCode: location?.sellingUnitCode,
                sellingUnitLat: location?.sellingUnitLocation.lat,
                sellingUnitLng: location?.sellingUnitLocation.lng,
            };
            break;
    }

    async function goClicked() {
        const storedLocation = userLocationReq.tag === "success" ? userLocationReq.value.stored : null;
        if (location) {
            if (!isSame(location, storedLocation)) {
                switch (location.scope) {
                    case "Global":
                        await userApi.setUserLocation({ scope: "Global" });
                        break;
                    case "Country":
                        await userApi.setUserLocation({ scope: "Country", countryCode: location.countryCode });
                        break;
                    case "SellingUnit":
                        await userApi.setUserLocation({
                            scope: "SellingUnit",
                            countryCode: location.countryCode,
                            sellingUnitCode: location.sellingUnitCode,
                        });
                        break;
                }
            }
            // If return URL specified as query param then navigate to it
            if (returnUrl) {
                window.location.href = returnUrl;
            } else {
                window.location.href = constructSalesUrl(location);
            }
        }
    }

    function renderLocationSelector() {
        if (userLocationReq.tag === "success" && countries) {
            return (
                <LocationSelector
                    initialLocation={initialLocation}
                    countries={countries.countries}
                    onLocationChange={(selection) => {
                        selection && locationsApi.getMetadata(selection).then(setLocation);
                    }}
                />
            );
        } else {
            return <LoadingIndicator />;
        }
    }

    return (
        <div className={styles.page}>
            <LogoOverlay />
            <div className={styles.mapSection}>
                <Link to="/map">
                    <MapChart className={styles.mapChart} zoom={mapZoom} eventStream={events} rates={rates} />
                </Link>
            </div>
            <div className={styles.selectSection}>
                <div className={styles.selectContainer}>
                    {languageAlert}
                    <p>{t`Where do you work?`}</p>
                    {renderLocationSelector()}
                    <LivligButton type="primary" disabled={location === null} onClick={goClicked}>
                        {t`Go`}
                    </LivligButton>
                </div>
            </div>
            <div className={styles.briefSection}>
                <h2>{t`Meet Livlig`}</h2>
                <p>
                    {t`A web application that puts real-time, actionable sales data insights at your fingertips, enabling
            data-driven decisions to improve our everyday business.`}
                </p>
                <p>
                    {t`With Livlig, you can follow any article in your store and see how many units of it you’ve sold at any point,
            each day. You can also compare the sales of today with other days, and with how you’re doing compared to
            other stores.`}
                </p>
            </div>
            <span className={styles.whiteFooter}>
                <Footer />
            </span>
        </div>
    );
}

/**
 * Shows a small alert using the supplied locale about there being translations in the specific locale.
 * @param locale The language, i.e. "sv" or "fi".
 * @param localNameOfLocale The local name of the locale, i.e. "Svenska" or "suomi".
 * @constructor
 */
const LocaleAlert: React.FC<{ locale: string; localNameOfLocale: string }> = ({ locale, localNameOfLocale }) => {
    const [locali18n, setLocali18n] = useState<I18n>();

    useEffect(() => {
        async function fn() {
            const { messages } = await import(`./locales/${locale}/messages.mjs`);
            const i18n = setupI18n();
            i18n.loadAndActivate({ locale, messages });

            setLocali18n(i18n);
        }

        fn();
    }, [locale]);

    function extractLocalLocaleName() {
        return localNameOfLocale;
    }

    if (locali18n) {
        return (
            <I18nProvider i18n={locali18n}>
                <Center>
                    <p>
                        <Trans>Language was detected</Trans>
                    </p>
                    <Trans>Do you want to switch language to {extractLocalLocaleName()}?</Trans>
                </Center>
                <Center>
                    <LivligButton
                        type={"primary"}
                        onClick={() => {
                            persistLanguageSetting(locale);
                            localeActivate(locale);
                        }}
                    >
                        <Trans>Yes, please</Trans>
                    </LivligButton>
                </Center>
            </I18nProvider>
        );
    } else {
        return null;
    }
};

const LocationSelector: React.FC<{
    initialLocation: LocationScope | null;
    countries: Array<Country>;
    onLocationChange: (selection: LocationScope | null) => void;
}> = ({ initialLocation, countries, onLocationChange }) => {
    const [showInternal, setShowInternal] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState<LocationScope | null>(initialLocation || null);
    useEffect(() => {
        if (selectedLocation) {
            switch (selectedLocation.scope) {
                case "Global":
                case "Country":
                case "SellingUnit":
                    onLocationChange(selectedLocation);
                    break;
            }
        }
    }, [selectedLocation]);
    const selectedCountryCode = countryCode(selectedLocation);
    const selectedSellingUnitCode = sellingUnitCode(selectedLocation);
    const country = countries.find((entry) => selectedCountryCode === entry.id);

    const shouldShowEnglishNamesToo = true;
    const lang = extractSelectedLanguage();

    const languages = Object.entries(locales)
        .sort((lhs, rhs) => lhs[1].localeCompare(rhs[1]))
        .map((locale) => {
            let label = locale[1];
            if (shouldShowEnglishNamesToo && locale[0] != "en") {
                // @ts-ignore
                let englishName = `${localesInEnglish[locale[0]]}`;
                label = `${locale[1]} (${englishName})`;
            }
            return {
                id: locale[0],
                label: label,
            };
        });

    return (
        <div className={styles.selectLocation}>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="lang"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Language</Trans>
                        </span>
                    }
                    value={lang}
                    onChange={(event) => {
                        const locale = event.target.value as string;
                        persistLanguageSetting(locale);
                        localeActivate(locale);
                    }}
                    hintText=""
                >
                    {languages.map((entry) => (
                        <Option key={entry.id} value={entry.id} name={entry.label} />
                    ))}
                </Select>
            </div>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="country"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Country</Trans>
                        </span>
                    }
                    value={selectedCountryCode || "all"}
                    onChange={(event) => {
                        const countryCode = event.target.value as string;
                        setSelectedLocation(
                            countryCode === "all" ? { scope: "Global" } : { scope: "Country", countryCode },
                        );
                    }}
                    hintText=""
                >
                    <Option key="all" value="all" name={t`All countries (Global)`} />
                    {countries.map((entry) => (
                        <Option key={entry.id} value={entry.id} name={translateCountryName(entry.name)} />
                    ))}
                </Select>
            </div>
            <div className={styles.selectBoxContainer}>
                <Select
                    id="su"
                    className={styles.selectBox}
                    label={
                        <span className={styles.selectLabel}>
                            <Trans>Selling unit</Trans>
                        </span>
                    }
                    value={selectedSellingUnitCode || "all"}
                    onChange={(event) => {
                        if (selectedCountryCode && selectedCountryCode !== "all") {
                            const sellingUnitCode = event.target.value as string;
                            const scope: LocationScope =
                                sellingUnitCode === "all"
                                    ? { scope: "Country", countryCode: selectedCountryCode }
                                    : { scope: "SellingUnit", countryCode: selectedCountryCode, sellingUnitCode };
                            setSelectedLocation(scope);
                        }
                    }}
                    hintText=""
                >
                    <Option key="all" value="all" name={t`All business units`} />
                    {country?.sellingUnits
                        .filter((entry) => showInternal || !entry.isInternal)
                        .map((entry) => (
                            <Option
                                key={entry.id}
                                value={entry.id}
                                name={entry.id.length === 8 ? entry.name : `${entry.name}*`}
                            />
                        ))}
                </Select>
            </div>
            <div>
                <Checkbox
                    id={"internal"}
                    value={""}
                    checked={showInternal}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setShowInternal(event.target.checked)}
                    label={
                        <span className={styles.internalCheckbox}>
                            <Trans>Also show internal locations</Trans>
                        </span>
                    }
                    title={t`If enabled, "Pick-up Points" will also be shown in the list.`}
                />
            </div>
        </div>
    );
};

function LogoOverlay() {
    return (
        <div className={styles.logoOverlay}>
            <Logo />
        </div>
    );
}

function isSame(location1: LocationScope, location2: LocationScope | null): boolean {
    if (location2) {
        switch (location1.scope) {
            case "Global":
                return location2.scope === "Global";
            case "Country":
                return location2.scope === "Country" && location1.countryCode === location2.countryCode;
            case "SellingUnit":
                return location2.scope === "SellingUnit" && location1.sellingUnitCode === location2.sellingUnitCode;
        }
    } else {
        return false;
    }
}

function constructSalesUrl(location: LocationScope): string {
    function baseUrl() {
        if (window.location.hostname === "localhost") {
            return `//localhost:3000`;
        } else {
            const domainTLD = extractDomainTLD();
            return `//item-sales.livlig.ingka.${domainTLD}`;
        }
    }

    switch (location.scope) {
        case "Global":
            return `${baseUrl()}/locations/${location.scope}`;
        case "Country":
            return `${baseUrl()}/locations/${location.scope}/${location.countryCode}`;
        case "SellingUnit":
            return `${baseUrl()}/locations/${location.scope}/${location.sellingUnitCode}`;
    }
}
