import React, { useEffect, useRef, useState } from "react";
import Globe, { GlobeMethods } from "react-globe.gl";

import globeImg from "./assets/earth-blue-marble.jpg";
import bumpImg from "./assets/earth-topology.png";
import backgroundImg from "./assets/night-sky.png";
import { MapEvent, useSalesStreams } from "./salesAPI";
import styles from "./Map3DView.module.css";
import { Helmet } from "react-helmet";
import { t } from "@lingui/macro";
import { NavHome } from "@ingka-livlig/frontend-lib";
import { Link } from "react-router-dom";

type MapPoint = {
    timestamp: number;
    lat: number;
    lng: number;
    altitude: number;
};

export const Map3DView = () => {
    const globeEl = useRef<GlobeMethods>();
    const [eventStream, , ,] = useSalesStreams({ scope: "Global", currency: { code: "EUR", name: "Euro" } });
    const [points, setPoints] = useState<MapPoint[]>([]);

    useEffect(() => {
        const eventSub = eventStream.subscribe((event) => {
            setPoints((prevPoints) => pruneOldPoints(prevPoints, event));
        });

        // Calculate longitude to center globe at based on current time
        const centerLongitude = () => {
            const d = new Date();
            // Move "back" time 10h so we center closer to when store opens
            d.setTime(d.getTime() - 10 * 60 * 60 * 1000);
            const utcMinutesSinceMidnight = d.getUTCHours() * 60 + d.getUTCMinutes();
            // Convert "minutes so far today" to longitude
            return (utcMinutesSinceMidnight / (60 * 24)) * 360;
        };

        // Do some sane initial positioning
        globeEl.current?.pointOfView({ lat: 40, lng: centerLongitude(), altitude: 1.8 });
        // Update longitudinal rotation based on current time every minute
        const timer = setInterval(() => globeEl.current?.pointOfView({ lng: centerLongitude() }, 200), 60 * 1000);

        return () => {
            eventSub.unsubscribe();
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={styles.container} data-testid="main">
            <Helmet>
                <title>{t`Real-time sales` + " | Livlig"}</title>
            </Helmet>
            <div className={styles.header}>
                <nav className={styles.navigation}>
                    <NavHome homeUrl={`/`} color="#fff" text={t`Real-time sales`} />
                </nav>
            </div>
            <div className={styles.globeContainer}>
                <Globe
                    ref={globeEl}
                    pointsData={points}
                    pointsTransitionDuration={100}
                    pointAltitude={"altitude"}
                    pointRadius={0.4}
                    globeImageUrl={globeImg}
                    bumpImageUrl={bumpImg}
                    backgroundImageUrl={backgroundImg}
                />
            </div>
        </div>
    );
};

function pruneOldPoints(existing: MapPoint[], next: MapEvent): MapPoint[] {
    const cutoff = Date.now() - 1500;
    const arr = existing.filter((event) => event.timestamp > cutoff);
    const halfCutoff = cutoff + 500;
    // Drop altitude on point to zero some time before
    arr.forEach((event) => {
        if (event.timestamp < halfCutoff) {
            event.altitude = 0;
        }
    });
    const nextConv: MapPoint = {
        timestamp: next.timestamp,
        lat: next.lat,
        // Readjusting longitude since the salesstream data is adjusted to align to the 2D map
        lng: next.lng + 12,
        // Make altitude of point logarithmic of total amount in Euro
        altitude: Math.log10(next.totalAmount / 10 + 1) / 3 + 0.02,
    };
    return [...arr, nextConv];
}
